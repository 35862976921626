<template>
  <div class="bg-black">
    <slide-banner />
    <div class="row justify-content-center m-0">
      <div class="container bg-dark mt-1 p-0">
        <div class="col-12 col-md-12 col-xl-12">
          <!-- <div class="mt-1">
          <font-awesome-icon
            class="blink_me"
            :icon="['fas', 'star']"
            style="width: 18px;"
          />
          <font-awesome-icon
            class="blink_me"
            :icon="['fas', 'star']"
            style="width: 18px;"
          />
          <font-awesome-icon
            class="blink_me"
            :icon="['fas', 'star']"
            style="width: 18px;"
          />
          <font-awesome-icon
            class="blink_me"
            :icon="['fas', 'star']"
            style="width: 18px;"
          />
        </div> -->
          <div
            class="row col-12 col-md-12 col-xl-12 mt-3 justify-content-between"
          >
            <div class="d-flex">
              <p class="font-weight-bolder font-header text-white">
                <span style="font-weight: bold; color: red">‣</span>สล็อต
              </p>
            </div>
            <div class="d-flex">
              <b-link href="/games/show#slot">
                <b-button variant="outline-light" size="sm" pill
                  >แสดงทั้งหมด</b-button
                >
              </b-link>
            </div>
          </div>
          <!-- start slot -->
          <div class="row mt-2" v-if="listSlot.length > 0">
            <div class="col-xl-6 d-none d-sm-none d-md-none d-xl-block">
              <div
                class="col-xl-12 h-100"
                @click="
                  onClickLoginGame(listSlot[0].code, 'slot', listSlot[0].gameId)
                "
              >
                <b-img
                  :src="listSlot[0].imgUrl"
                  style="width: 600px; height: 350px"
                  class="game"
                />
              </div>
            </div>
            <div class="col-xl-6 row">
              <div
                class="col-xl-4 col-md-4 col-6 d-xl-none d-lg-none mb-1"
                @click="
                  onClickLoginGame(listSlot[0].code, 'slot', listSlot[0].gameId)
                "
              >
                <b-img :src="listSlot[0].imgUrl" class="w-100 game" />
              </div>
              <div
                class="col-xl-4 col-md-4 col-6 mb-1"
                @click="
                  onClickLoginGame(listSlot[1].code, 'slot', listSlot[1].gameId)
                "
              >
                <b-img :src="listSlot[1].imgUrl" class="w-100 h-100 game" />
              </div>
              <div
                class="col-xl-4 col-md-4 col-6 mb-1"
                @click="
                  onClickLoginGame(listSlot[2].code, 'slot', listSlot[2].gameId)
                "
              >
                <b-img :src="listSlot[2].imgUrl" class="w-100 h-100 game" />
              </div>
              <div
                class="col-xl-4 col-md-4 col-6 mb-1"
                @click="
                  onClickLoginGame(listSlot[3].code, 'slot', listSlot[3].gameId)
                "
              >
                <b-img :src="listSlot[3].imgUrl" class="w-100 h-100 game" />
              </div>
              <div
                class="col-xl-4 col-md-4 col-6 mb-1"
                @click="
                  onClickLoginGame(listSlot[4].code, 'slot', listSlot[4].gameId)
                "
              >
                <b-img :src="listSlot[4].imgUrl" class="w-100 h-100 game" />
              </div>
              <div
                class="col-xl-4 col-md-4 col-6 mb-1"
                @click="
                  onClickLoginGame(listSlot[5].code, 'slot', listSlot[5].gameId)
                "
              >
                <b-img :src="listSlot[5].imgUrl" class="w-100 h-100 game" />
              </div>
              <div
                class="
                  col-xl-4 col-md-4 col-6
                  d-none d-sm-none d-md-none d-xl-block
                  mb-1
                "
                @click="
                  onClickLoginGame(listSlot[6].code, 'slot', listSlot[6].gameId)
                "
              >
                <b-img :src="listSlot[6].imgUrl" class="w-100 h-100 game" />
              </div>
            </div>
          </div>
          <!-- end Slot -->
          <div
            class="row col-12 col-md-12 col-xl-12 mt-3 justify-content-between"
          >
            <div class="d-flex">
              <p class="font-weight-bolder font-header text-white">
                <span style="font-weight: bold; color: red">‣</span>ยิงปลา
              </p>
            </div>
            <div class="d-flex">
              <b-link href="/games/show#fishing">
                <b-button variant="outline-light" size="sm" pill
                  >แสดงทั้งหมด</b-button
                >
              </b-link>
            </div>
          </div>
          <!-- start dealer -->
          <div class="row mt-2 mb-2" v-if="listFishing.length > 0">
            <div class="col-xl-6 d-none d-sm-none d-md-none d-xl-block">
              <div
                class="col-xl-12 h-100"
                @click="onClickLoginGame(listFishing[0].code, 'slot', listFishing[0].gameId)"
              >
                <b-img
                  :src="listFishing[0].imgUrl"
                  style="width: 600px; height: 350px"
                  class="game rounded"
                />
              </div>
            </div>
            <div class="col-xl-6 row">
              <div class="col-xl-4 col-md-4 col-6 d-xl-none d-lg-none mb-1"
                @click="onClickLoginGame(listFishing[0].code, 'slot', listFishing[0].gameId)"
              >
                <b-img
                  :src="listFishing[0].imgUrl"
                  class="w-100 game fishing rounded"
                />
              </div>
              <div class="col-xl-4 col-md-4 col-6 mb-1" 
                @click="onClickLoginGame(listFishing[1].code, 'slot', listFishing[1].gameId)"
              >
                <b-img
                  :src="listFishing[1].imgUrl"
                  class="w-100 game fishing rounded"
                />
              </div>
              <div class="col-xl-4 col-md-4 col-6 mb-1"
                @click="onClickLoginGame(listFishing[2].code, 'slot', listFishing[2].gameId)"
              >
                <b-img
                  :src="listFishing[2].imgUrl"
                  class="w-100 game fishing rounded"
                />
              </div>
              <div class="col-xl-4 col-md-4 col-6"
                @click="onClickLoginGame(listFishing[3].code, 'slot', listFishing[3].gameId)"
              >
                <b-img
                  :src="listFishing[3].imgUrl"
                  class="w-100 game fishing rounded"
                />
              </div>
              <div class="col-xl-4 col-md-4 col-6"
                @click="onClickLoginGame(listFishing[4].code, 'slot', listFishing[4].gameId)"
              >
                <b-img
                  :src="listFishing[4].imgUrl"
                  class="w-100 game fishing rounded"
                />
              </div>
              <div class="col-xl-4 col-md-4 col-6"
                @click="onClickLoginGame(listFishing[5].code, 'slot', listFishing[5].gameId)"
              >
                <b-img
                  :src="listFishing[5].imgUrl"
                  class="w-100 game fishing rounded"
                />
              </div>
              <div
                class="
                  col-xl-4 col-md-4 col-6
                  d-none d-sm-none d-md-none d-xl-block
                "
                @click="onClickLoginGame(listFishing[6].code, 'slot', listFishing[6].gameId)"
              >
                <b-img
                  :src="listFishing[6].imgUrl"
                  class="w-100 game fishing rounded"
                />
              </div>
            </div>
          </div>
          <!-- end Dealer -->
        </div>
      </div>
      <!-- end container -->
      <div class="row ml-1 mr-1 mt-1 mb-2">
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('ag')"
        >
          <b-img
            :src="require('@/assets/images/production/baccarat/ag.png')"
            class="w-100"
          />
        </div>
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('bg')"
        >
          <b-img
            :src="require('@/assets/images/production/baccarat/bg.png')"
            class="w-100"
          />
        </div>
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('dg')"
        >
          <b-img
            :src="require('@/assets/images/production/baccarat/dg.png')"
            class="w-100"
          />
        </div>
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('ebet')"
        >
          <b-img
            :src="require('@/assets/images/production/baccarat/ebet.png')"
            class="w-100"
          />
        </div>
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('greendragon')"
        >
          <b-img
            :src="
              require('@/assets/images/production/baccarat/greendragon.png')
            "
            class="w-100"
          />
        </div>
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('pragmatic')"
        >
          <b-img
            :src="require('@/assets/images/production/baccarat/pragmatic.png')"
            class="w-100"
          />
        </div>
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('pt')"
        >
          <b-img
            :src="require('@/assets/images/production/baccarat/pt.png')"
            class="w-100"
          />
        </div>
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('sa')"
        >
          <b-img
            :src="require('@/assets/images/production/baccarat/sa.png')"
            class="w-100"
          />
        </div>
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('sexy')"
        >
          <b-img
            :src="require('@/assets/images/production/baccarat/sexy.png')"
            class="w-100"
          />
        </div>
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('allbet')"
        >
          <b-img
            :src="require('@/assets/images/production/baccarat/allbet-bg.png')"
            class="w-100"
          />
        </div>
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('betgame')"
        >
          <b-img
            :src="require('@/assets/images/production/baccarat/betgame.png')"
            class="w-100"
          />
        </div>
        <div
          class="col-xl-2 col-md-4 col-6 d-flex align-items-center game"
          @click="onClickLoginGame('atom')"
        >
          <b-img
            :src="require('@/assets/images/production/baccarat/atom.png')"
            class="w-100"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BBadge,
  BCardText,
  BImg,
  BOverlay,
  BLink,
} from "bootstrap-vue";
import { Carousel, Slide } from "vue-carousel";
import SlideBanner from "./SlideBanner.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import ModalLogin from '../auth/Login.vue'
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// import 'vueperslides/dist/vueperslides.css'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BBadge,
    BImg,
    BCardText,
    BOverlay,
    BLink,
    SlideBanner,
    FeatherIcon,
    Carousel,
    Slide,
  },
  data() {
    return {
      pauseOnHover: true,
      autoPlaying: true,
      internalAutoPlaying: true,
      slide: [
        {
          id: "slide-1",
          title: 'Slide <b style="font-size: 1.3em;color: yellow">#1</b>',
          content: "",
        },
        // Other slides...
      ],
      datasNewproduct: [],
      dataBestsellproduct: [],
      listSlot: [],
      listFishing: [],
    };
  },
  computed: {
    perpage() {
      const size = this.$store.getters["app/currentBreakPoint"];
      // eslint-disable-next-line no-nested-ternary
      return size === "xs" ? 1 : size === "md" || size === "sm" ? 2 : 4;
    },
    sizepagee() {
      return this.$store.getters["app/currentBreakPoint"];
    },
  },
  watch: {},
  created() {
    this.loadGame();
  },
  methods: {
    async loadGame() {
      await this.loadSlot();
      await this.loadBaccarat();
    },
    async loadSlot() {
      await this.$store
        .dispatch("game/getSlot")
        .then((result) => {
          console.log(result.data);
          if (result.data.success) {
            this.listSlot = result.data.itemsslot;
            this.listFishing = result.data.itemfishing;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadBaccarat() {
      await this.$store
        .dispatch("game/getBaccarat")
        .then((result) => {
          if (result.data.success) {
            this.listLive = result.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onClickLoginGame(val, model, gameid) {
      const token = localStorage.getItem("auth_token");
      const auth = this.$store.state.auth.auth;
      if (token !== "" && token !== undefined && auth) {
        this.$store.commit("game/SET_LOADING", true);
        this.$store
          .dispatch("game/loginGame", { type: val, model, gameId: gameid })
          .then((result) => {
            if (result.data.success) {
              // window.open(result.data.items[0].url);
              // window.location.assign(result.data.items[0].url);
              this.$router.push({
                name: "login-game",
                params: {
                  url: result.data.items[0].url,
                },
              });
              this.$store.commit("game/SET_LOADING", false);
            } else {
              this.$store.commit("game/SET_LOADING", false);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: "ล็อกอินเกมส์ไม่สำเร็จ",
                },
              });
            }
          })
          .catch((error) => {
            this.$store.commit("game/SET_LOADING", false);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                icon: "AlertCircleIcon",
                variant: "danger",
                text: "ล็อกอินเกมส์ไม่สำเร็จ",
              },
            });
            this.isLoading = false;
          });
      } else {
        this.$store.commit("customer/SET_OPEN_REGISTER");
        this.$store.commit("customer/SET_TYPE_MODAL", "login");
      }
    },
  },
};
</script>

<style scoped>
.carousel-item {
  max-height: 55vh;
}
.font-header {
  font-size: 27px;
  color: black;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 190px;
  margin: auto;
}
.card-item {
  max-width: 20rem;
  width: 15rem;
  margin: auto;
  height: 380px;
}
.shadow-item {
  box-shadow: 1px -1px 5px -1px rgba(108, 108, 108, 0.6) !important;
}

@keyframes blink {
  from {
    color: black;
  }
  to {
    color: yellow;
  }
}

.heightmobile img {
  height: 180px;
}

.game {
  cursor: pointer;
}

.game:hover {
  transform: translate(3px, 3px);
}

.slot-game {
  border: 1px solid red;
  box-shadow: 0 4px 8px 0 black, 0 6px 20px 0 black;
}

.fishing {
  height: 150px;
}
</style>
